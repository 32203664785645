define(['app', 'ePopup', 'siteObj'], function(app, ePopup, siteObj) {
  
  let egift = {
    pageId: 0,
    page: 0,
    pasteTimeout: 10, //10 milliseconds
    isMobile: false,
    errorCls: 'eGift_error',
    buyButtonCls: '.eGift_buttonSend',
    nextButtonCls: '.eGift_buttonNext',
    backButtonCls: '.eGift_buttonBack',
    hideCls: 'eGift-hide',
    showCls: 'eGift-show',
    container: '.eGift_container',
    headers: '.eGift_title',
    headerMain: '.eGift_mainTitle',
    headerEmail: '.eGift_emailTitle',
    headerMessage: '.eGift_messageTitle',
    headerConfirm: '.eGift_confirmTitle',
    headerBasket: '.eGift_basketTitle',
    pageOne: {
      id: '.eGift_formStep1'
    },
    pageTwo: {
      id: '.eGift_formStep2'
    },
    pageThree: {
      id: '.eGift_formStep3'
    },
    pageFour: {
      id: '.eGift_formStep4'
    },
    // form fields
    nameField: '#eGift_recipientName',
    emailField: '#eGift_recipientEmail',
    messageField: '#eGift_recipientMessage',
    messageFieldCount: '.eGift_messageCount',
    // form preview fields
    namePreviewField: '.eGift_recipientNamePreview',
    emailPreviewField: '.eGift_recipientEmailPreview',
    messagePreviewField: '.eGift_recipientMessagePreview',
    bullet: '.eGift_bullet',
    bulletActiveCls: 'eGift_bulletActive',
    messageMaxLen: 255,
    voucherCodeMinLength: 13,
    pages: [],
    fields: [],

    /**
     * @name: init
     * @type : function
     * @param: none
     * @description: initiates class and runs default functions
     */

    init: function() {
      egift.initEgiftForm();
    },
    /**
     * @name: initEgiftForm
     * @type : function
     * @param: none
     * @description: initiates class and runs default functions
     */

    initEgiftForm: function() {
      egift.addClass('body', 'eGift_product');
      egift.isMobile = document.querySelector('.eGift_mobile');
      egift.pageOne.header = egift.headerMain;
      egift.pageTwo.header = egift.headerMain;
      egift.pageThree.header = egift.headerConfirm;
      // set up the pages and fields in array
      egift.pages = [egift.pageOne, egift.pageTwo, egift.pageThree];
      if (egift.isMobile !== null) {
        egift.pageTwo.header = egift.headerEmail;
        egift.pageThree.header = egift.headerMessage;
        egift.pageFour.header = egift.headerConfirm;
        egift.pages = egift.pages = [egift.pageOne, egift.pageTwo, egift.pageThree, egift.pageFour];
      }
      egift.activateButtons();
      egift.activateFormFields();
      egift.setFieldValue(egift.messageFieldCount, egift.messageMaxLen);
      egift.showPage();
    },
    /**
     * @name: getUrlVars
     * @type : function
     * @param: none
     * @description: return url parameter values
     * @usage: example,  let gotoVal = getUrlVars()["goto"] ;
     */
    getUrlVars: function($win) {
      let $window = $win || window,
        vars = {},
        hash;
      let hashes = $window.location.href.slice($window.location.href.indexOf('?') + 1).split('&') || [];
      for (let i = 0, len = hashes.length; i < len; i++) {
        hash = hashes[i].split('=');
        vars[hash[0]] = hash[1];
      }
      return vars;
    },

    /**
     * @name: addListener
     * @type : function
     * @param: domref, evt, func
     * @description: adds Event listeners to a Dom Element
     */
    addListener: function(domref, evt, func) {
      if (egift.notEmpty(evt) && egift.notEmpty(domref) && egift.notEmpty(func)) {
        app.element.init(domref).on(evt, func);
      }
    },
    /**
     * @name: activateButtons
     * @type : function
     * @param: none
     * @description: adds Event listeners to buyButton, nextButton and backButton
     */
    activateButtons: function() {
      egift.addListener(egift.buyButtonCls, 'click', egift.showPopup);
      egift.addListener(egift.nextButtonCls, 'click', egift.nextButtonAction);
      egift.addListener(egift.backButtonCls, 'click', egift.backButtonAction);
    },
    /**
     * @name: activateFormFields
     * @type : function
     * @param: none
     * @description: adds Event listeners to form fields
     */
    activateFormFields: function() {
      egift.addListener(egift.nameField, 'keyup', egift.validate);
      egift.addListener(egift.emailField, 'keyup', egift.validate);
      egift.addListener(egift.messageField, 'keyup', egift.validate);
      egift.addListener(egift.messageField, 'keyup', egift.textCounter);
      egift.addListener(egift.messageField, 'keydown', egift.textCounter);
      // paste listener
      egift.addListener(egift.nameField, 'paste', egift.checkPastedContent);
      egift.addListener(egift.emailField, 'paste', egift.checkPastedContent);
      egift.addListener(egift.messageField, 'paste', egift.checkPastedContent);
      let msgField = document.querySelector(egift.messageField);
      if (msgField !== null) {
        msgField.setAttribute('maxlength', egift.messageMaxLen);
      }
    },
    /**
     * @name: triggerEvent
     * @type : function
     * @param: none
     * @description: triggers Events on dom Element
     */
    triggerEvent: function(el, type) {
      if (document.createEvent) {
        // modern browsers, IE9+
        let e = document.createEvent('HTMLEvents');
        if (e && e.initEvent && el && el.dispatchEvent) {
          e.initEvent(type, false, true);
          el.dispatchEvent(e);
        }
      }
    },
    /**
     * @name: checkPastedContent
     * @type : function
     * @param: none
     * @description: triggers when user paste values to form fields
     */
    checkPastedContent: function(evt) {
      setTimeout(function() {
        egift.triggerEvent(evt.target, 'keyup');
      }, egift.pasteTimeout);
    },
    /**
     * @name: resetForm
     * @type : function
     * @param: e[@type: domElementEvent]
     * @description: resets the for to page 1 and removes all values
     */
    resetForm: function(e) {
      // fields to reset
      egift.fields = [egift.nameField, egift.emailField, egift.messageField];
      for (let i = 0; i < egift.fields.length; i++) {
        egift.setFieldValue(egift.fields[i], '', 1); // reset
      }
      egift.pageId = 0;
      egift.showPage(0);
      egift.clickHalt(e);
    },
    /**
     * @name: addClass
     * @type : function
     * @param: elem[@type: String], clsToAdd[@type: String]
     * @description: adds a class to a Dom Element
     */
    addClass: function(elem, clsToAdd) {
      if (elem && clsToAdd) {
        app.element.init(elem).forEach(function() {
          app.element.addClass(clsToAdd);
        });
      }
    },
    /**
     * @name: removeClass
     * @type : function
     * @param: elem[@type: String], clsToRemove[@type: String]
     * @description: removes a class from a Dom Element
     */
    removeClass: function(elem, clsToRemove) {
      if (elem && clsToRemove) {
        app.element.init(elem).forEach(function() {
          app.element.removeClass(clsToRemove);
        });
      }
    },
    /**
     * @name: showActiveDot
     * @type : function
     * @param: hId[@type: String]
     * @description: show / hide page titles
     */
    showActiveDot: function(hId) {
      // hide headers
      egift.removeClass(egift.bullet, egift.bulletActiveCls);
      if (hId) {
        egift.addClass(egift.bullet + hId, egift.bulletActiveCls);
      }
    },
    /**
     * @name: showHeader
     * @type : function
     * @param: hId[@type: String]
     * @description: show / hide page titles
     */
    showHeader: function(hId) {
      // hide headers
      egift.addClass(egift.headers, egift.hideCls);
      if (hId) {
        egift.removeClass(hId, egift.hideCls);
      }
    },
    /**
     * @name: showPage
     * @type : function
     * @param: pId[@type: Integer]
     * @description: manages page display
     */
    showPage: function(pId) {
      pId = parseInt(pId, 10) || egift.pageId;
      let page = ((pId > -1) && egift.pages[pId]);
      if (page) {
        if (page.header === egift.headerConfirm) {
          egift.updateSiteObjAndPreviewPage();
          egift.manageButtonStates();
        }
        egift.removeClass('.eGift_step', egift.showCls);
        egift.showHeader(page.header);
        if (egift.isMobile) {
          egift.showActiveDot(parseInt(pId + 1, 10));
        }
        egift.addClass(page.id, egift.showCls);
      } else {
        // call post fountion here
        egift.addToBasket();
      }
    },
    /**
     * @name: addToBasket
     * @type : function
     * @param:  none
     * @description: adds
     */
    addToBasket: function() {
      // if popup exists, close it
      if (egift.thePopup && egift.thePopup.close) {
        egift.thePopup.close();
        egift.resetForm();
      }
      let addToBasketButton = document.querySelector('.js-buynow');
      if (addToBasketButton) {
        addToBasketButton.click();
      }
    },
    /**
     * @name: getFieldValue
     * @type : function
     * @param:  pId[@type: String]
     * @description: returns the value of a input field
     */
    getFieldValue: function(fid) {
      let result = '';
      if (egift.notEmpty(fid)) {
        let selector = document.querySelector(fid);
        result = (egift.notEmpty(selector)) ? selector.value : '';
      }
      return result;
    },
    /**
     * @name: setFieldValue
     * @type : function
     * @param: fid[@type: String], val[@type: String], reset[@type: Integer]
     * @description: initiates class and runs default functions
     */
    setFieldValue: function(fid, val, reset) {
      let field = document.querySelector(fid);
      reset = (!reset) ? 0 : parseInt(reset, 10);
      if (field !== null) {
        if (reset > 0) {
          field.value = val;
        } else {
          field.innerText = val;
        }
      }
    },
    /**
     * @name: textCounter
     * @type : function
     * @param: none
     * @description: returns remaining characters allowed in text field
     */
    textCounter: function(evt) {
      let msgval = (evt && evt.target && evt.target.value) ? evt.target.value : '';
      if (msgval.trim() !== '') {
        let len = msgval.length || 0,
          remain = parseInt((egift.messageMaxLen - len), 10);
        remain = (remain < 0) ? 0 : remain;
        egift.setFieldValue(egift.messageFieldCount, remain);
        // prevent adding more than messageMaxLen characters
        if (len > egift.messageMaxLen) {
          let validStr = msgval.slice(0, egift.messageMaxLen);
          egift.setFieldValue(egift.messageField, validStr);
        }
      }
    },
    /**
     * @name: updateSiteObjAndPreviewPage
     * @type : function
     * @param: none
     * @description: populates the preview page
     */
    updateSiteObjAndPreviewPage: function() {
      // set
      let nameField = egift.getFieldValue(egift.nameField),
        emailField = egift.getFieldValue(egift.emailField),
        messageField = egift.getFieldValue(egift.messageField),
        productField = document.querySelector('.product-details .product-price'),
        productPrice = (productField !== null) ? productField.innerText : '';
      egift.setFieldValue('.eGift_totalValue', productPrice);
      // update preview;
      egift.setFieldValue(egift.namePreviewField, nameField);
      egift.setFieldValue(egift.emailPreviewField, emailField);
      egift.setFieldValue(egift.messagePreviewField, messageField);
      // update siteObj.egift;
      if (siteObj) {
        siteObj.egift = {}; // reset object
        siteObj.egift.receiverName = nameField;
        siteObj.egift.receiverAddress = emailField;
        siteObj.egift.messageForReceiver = messageField;
        egift.setBasketErrorMsgHtml(siteObj.egift);
      }
    },
    /**
     * @name: setBasketErrorMsgHtml
     * @type : function
     * @param: e[@type: domElementEvent]
     * @description: next Button functions
     */
    setBasketErrorMsgHtml: function(obj) {

      let productNameField = document.querySelector('.product-details .product-title'),
        selectedSubscription = document.querySelector('.subscriptionsPage_label.checked .subscriptions__term'),
        productDescription = '';
      if (productNameField) {
        productDescription = productNameField.innerText;
        if (selectedSubscription) {
          productDescription += ' - ' + selectedSubscription.innerText;
        }
      }

      egift.setFieldValue('.eGift_productDescription', productDescription);
      let errorMsgHtml = document.querySelector('.eGift_basketError');
      if (obj && errorMsgHtml) {
        obj.errorMessage = errorMsgHtml.innerHTML;
      }

    },
    /**
     * @name: nextButtonAction
     * @type : function
     * @param: e[@type: domElementEvent]
     * @description: next Button functions
     */
    nextButtonAction: function(e) {
      egift.pageId = egift.pageId + 1;
      egift.showPage(egift.pageId);
      egift.manageButtonStates(e);
      egift.clickHalt(e);
    },
    /**
     /**
     * @name: backButtonAction
     * @type : function
     * @param: e[@type: domElementEvent]
     * @description: back Button functions
     */
    backButtonAction: function(e) {
      egift.pageId = (egift.pageId - 1 < 0) ? 0 : egift.pageId - 1;
      egift.showPage(egift.pageId);
      egift.manageButtonStates(e);
      egift.clickHalt(e);
    },
    /**
     * @name: notEmpty
     * @type : function
     * @param: v[@type: any]
     * @description: initiates class and runs default functions
     */
    notEmpty: function(v) {
      let retval = true;
      if (typeof (v) === 'string') {
        v = v.trim();
      }
      retval = (v !== '' && v !== null && v !== undefined && v !== ' ');
      return retval;
    },
    /**
     * @name: isEmail
     * @type : function
     * @param: email[@type: string]
     * @description: returns true for valid emails only
     */
    isEmail: function(email) {
      let reg = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z]{2,6})$/;
      if (egift.notEmpty(email)) {
        return reg.test(email);
      } else {
        return false;
      }
    },
    /**
     * @name: validate
     * @type : function
     * @param: evt[@type: domElementEvent]
     * @description: validates form and triggers next action
     */
    validate: function(evt) {
      let input = (evt && evt.target) ? evt.target : null,
        type = (input && input.getAttribute) ? input.getAttribute('type') : '',
        val = (input && input.value) ? input.value : '',
        isValid = false;
      if (type === 'text' && egift.notEmpty(val)) {
        isValid = true;
      } else if (type === 'email' && egift.isEmail(val)) {
        isValid = true;
      }
      let iId = (input && input.id) ? input.id : 'errorElem';
      if (!isValid) {
        egift.addClass('#' + iId, egift.errorCls);
        egift.manageButtonStates(evt);
      } else {
        egift.removeClass('#' + iId, egift.errorCls);
        egift.manageButtonStates(evt);
      }
      egift.clickHalt(evt);
    },
    /**
     * @name: clickHalt
     * @type : function
     * @param: e[@type: domElementEvent]
     * @description: prevents default click action
     */
    clickHalt: function(e) {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
    },
    /**
     * @name: clickHalt
     * @type : function
     * @param: elem[@type: domElement]
     * @description: enables a button
     */
    enableButton: function(elem) {
      if (!elem) {
        return;
      }
      elem.removeAttribute('disabled');
    },
    /**
     * @name: clickHalt
     * @type : function
     * @param: elem[@type: domElement]
     * @description: disables a button
     */
    disableButton: function(elem) {
      if (!elem) {
        return;
      }
      elem.setAttribute('disabled', 'disabled');
    },
    /**
     * @name: isFieldValid
     * @type : function
     * @param: domref[@type: string], val[@type: any]
     * @description: checks if a field is valid
     */
    isFieldValid: function(domref, val) {
      let retval = false,
        errorFld,
        obj = document.querySelector(domref);
      if (obj) {
        errorFld = obj.classList.contains(egift.errorCls);
        retval = (!errorFld && egift.notEmpty(val));
      }
      return retval;
    },
    /**
     * @name: currentPageValid
     * @type : function
     * @param: none
     * @description: checks a Page and validates page by page basis
     */
    currentPageValid: function() {
      let pId = egift.pageId,
        page = egift.pages[pId],
        pageFields = [],
        pageValid = false;
      switch (page) {
        case egift.pageOne:
          pageFields = (egift.isMobile) ? [egift.nameField] : [egift.nameField, egift.emailField];
          break;
        case egift.pageTwo:
          pageFields = (egift.isMobile) ? [egift.emailField] : [egift.messageField];
          break;
        case egift.pageThree:
          pageFields = (egift.isMobile) ? [egift.messageField] : [];
          break;
        default:
          pageFields = [];
          pageValid = true;
      }
      if (!pageFields.length) {
        pageValid = true;
      } else {
        for (let i = 0; i < pageFields.length; i++) {
          let fVal = egift.getFieldValue(pageFields[i]);
          pageValid = egift.isFieldValid(pageFields[i], fVal);
          if (!pageValid) {
            break;
          }
        }
      }
      return pageValid;
    },
    /**
     * @name: manageButtonStates
     * @type : function
     * @param: e[@type: domElementEvent], isTest[@type: Integer]
     * @description: controls when to enanble / disable a button
     */
    manageButtonStates: function(e) {
      // reset buttons
      let nextButton = document.querySelector(egift.nextButtonCls),
        backButton = document.querySelector(egift.backButtonCls);
      egift.disableButton(backButton);
      egift.disableButton(nextButton);
      if (egift.currentPageValid()) {
        if (egift.pageId > 0) {
          egift.enableButton(backButton);
          egift.enableButton(nextButton);
        } else {
          egift.disableButton(backButton);
          egift.enableButton(nextButton);
        }
      } else {
        if (egift.pageId > 0) {
          egift.enableButton(backButton);
        }
      }
      egift.clickHalt(e);
    },
    /**
     * @name: showPopup
     * @type : function
     * @param: e[@type: domElementEvent]
     * @description: runs the popup function
     */
    showPopup: function(e) {
      let content = document.querySelector(egift.container);
      egift.thePopup = new ePopup(content, 'eGift_componentPopup', true);
      egift.clickHalt(e);
      egift.addListener('.js-popup-close-btn', 'click', egift.resetForm);
    }
  };
  egift.init();
  return egift;
});
